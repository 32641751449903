<template>
	<div class="error">
		<img src="../assets/404.png" />
		<br />
		<img src="../assets/404text.png" />
		
	</div>
</template>

<script>
	export default{
		methods:{
			
		}
	}
</script>

<style scoped="scoped">
	.error{
		height: 100%;
		margin-top: 10%;
		align-items: center;
	}
	
</style>
