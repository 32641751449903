<template>
	<div class="hxmain">
		<YsmHead></YsmHead>
		<router-view></router-view>
	</div>
</template>

<script>
	import YsmHead from "../../components/hx/hx-head.vue";
	export default{
		mounted:function(){
			this.$router.push("/hxmain/hxlatestorder");
		},
		components:{
			YsmHead
		}
	}
</script>

<style scoped="scoped">
	.hxmain{
		width: 100%;
		height: 100%;
		background-image: url(../../assets/hximg/hxbeijing.jpg);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		overflow: hidden;
	}
	footer{
		
	}
</style>
