<template>
	<div class="yh">
		<div class="ysm-head">
				<img src="../../assets/hximg/hxlogo222.png" width="340" style="float: left;" />
				<div style="float: right;padding-right: 1.25rem;position: relative;">
					<img src="../../assets/hximg/hxphone.png" width="30px" style="vertical-align: middle;" >&nbsp;<span style="margin-right: 1.25rem;font-weight: normal;">0531-88762550</span>
					<img src="../../assets/hximg/hxhome.png" width="22.5px" style="vertical-align: middle;padding-bottom: 0.135rem;" >&nbsp;<a style="margin-right: 1.25rem;color:#000000;" href="http://www.techlion.com.cn/">官网首页</a>
					<span  style="margin-right: 0.625rem;display: inline-block;font-weight: bolder;cursor: pointer;" @click="goSettings" >{{dname}}</span>
					<!-- <span style="margin-right: 16px;">
						<el-dropdown>
						  <span class="el-dropdown-link" style="cursor: pointer;font-weight: bolder;">
						      {{dname}}<i class="el-icon-arrow-down el-icon--right"></i>
						    </span>
						  <el-dropdown-menu slot="dropdown" class="info">
						    <el-dropdown-item @click.native="goSettings">个人信息</el-dropdown-item>
						    <el-dropdown-item @click.native="modifyPwd">修改密码</el-dropdown-item>
						  </el-dropdown-menu>
						</el-dropdown>
					</span> -->
					<button class="logout" @click="logout">退出</button>
				</div>
		</div>
		<div class="nav1">
			<div>
				<a style="padding-left: 2.125rem;"@click="goMain">瓠犀/fas隐形病例切换</a>
				<a @click="goOrder">瓠犀全部订单</a>
				<a @click="settings">设置</a>
			</div>
			<div class="saveAndback">
				<a class="print die"  @click="printOrder">打印</a>
				<a class="saveb" @click="SaveOrder">保存</a>
				<a class="fh" @click="fanHui">返回</a>
			</div>
				
		</div>
		<div class="fun sub-menue" v-if="false">
			<div @click="back" style="display: flex;flex-direction: column;align-items: center;border-right: 1px solid #0D8EA4;cursor: pointer;padding-right: 0.625rem;" >
				<img src="../../assets/hximg/arrow-left.png" width="70%" />
				<span style="color:#0D8EA4;">返回</span>
			</div>
			<!-- <div @click="goAddOrder" style="display: flex;flex-direction: column;align-items: center;margin-left: 0.925rem;cursor: pointer;" >
				<img id="addfile" :src="src" style="width: 33px;height: 33px;" @mouseenter="change" @mouseleave="leavechange" />
				<span id="addO" style="color:#FFFFFF;font-size: 0.9375rem;">添加订单</span>
			</div> -->
			
		</div>
		<div class="fun1 sub-menue1"  v-if="false">
			<div @click="back" style="display: flex;flex-direction: column;align-items: center;border-right: 1px solid #0D8EA4;cursor: pointer;padding-right: 0.625rem;" >
				<!-- <img src="../../assets/hximg/arrow-left.png" width="70%" /> -->
				<img src="../../assets/hximg/arrow-left.png" style="width: 29px; height: 29px;" />
				<span style="color:#0D8EA4 ;">返回</span>
			</div>
			<div  @click="SaveOrder" style="display: flex;flex-direction: column;align-items: center;margin-left: 0.925rem;cursor: pointer;" >
				<img id="addfile" :src="savesrc" width="60%" @mouseenter="entersave" @mouseleave="leavesave" />
				<span id="bc" style="color:#FFFFFF;font-size: 0.9375rem;">保存</span>
			</div>
			<!-- <div @click="printOrder" style="display: flex;flex-direction: column;align-items: center;margin-left: 0.925rem;cursor: pointer;" >
				<img id="addfile" :src="printsrc" width="60%" @mouseenter="enterprint" @mouseleave="leaveprint" />
				<span id="dy" style="color:#FFFFFF;font-size: 0.9375rem;">打印</span>
			</div> -->
		</div>
		<div class="fun2 sub-menue2"  v-if="false">
			<div @click="back" style="display: flex;flex-direction: column;align-items: center;border-right: 1px solid #0D8EA4;cursor: pointer;padding-right: 0.625rem;" >
				<!-- <img src="../../assets/hximg/arrow-left.png" width="70%" /> -->
				<img src="../../assets/hximg/arrow-left.png" style="width: 29px; height: 29px;" />
				<span style="color:#0D8EA4 ;">返回</span>
			</div>
			
			<div @click="printOrder" style="display: flex;flex-direction: column;align-items: center;margin-left: 0.925rem;cursor: pointer;" >
				<img id="addfile" :src="printsrc" width="60%" @mouseenter="enterprint" @mouseleave="leaveprint" />
				<span id="dy" style="color:#FFFFFF;font-size: 0.9375rem;">打印</span>
			</div>
		</div>
		<div class="fun3 sub-menue3"  v-if="false">
			<div @click="back" style="display: flex;flex-direction: column;align-items: center;border-right: 1px solid #0D8EA4;cursor: pointer;padding-right: 0.625rem;" >
				<!-- <img src="../../assets/hximg/arrow-left.png" width="70%" /> -->
				<img src="../../assets/hximg/arrow-left.png" style="width: 29px; height: 29px;" />
				<span style="color:#0D8EA4 ;">返回</span>
			</div>
		</div>
		<el-dialog @close="resetpwdForm('pwdForm')" title="修改密码" :visible.sync="dialogFormVisible" width="30%"
			top="30vh">
			<el-form :model="pwdForm" ref="pwdForm" :rules="pwdFormRules">
				<el-form-item label="旧密码"  prop="oldPassword">
					<el-input type="password" v-model="pwdForm.oldPassword" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="新密码"  prop="password">
					<el-input type="password" v-model="pwdForm.password" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="确认密码"  prop="rePassword">
					<el-input type="password" v-model="pwdForm.rePassword" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="modifyPwd('pwdForm')">确 定</el-button>
			</div>
		</el-dialog>
	</div>
	
	
</template>

<script>
	
	export default{
		
		data:function(){
			return{
				dialogFormVisible: false,
				pwdForm: {
					id: '',
					password: '',
					oldPassword: '',
					rePassword: ''
				},
				pwdFormRules: {
					oldPassword: [{
						required: true,
						message: '请输入原密码',
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: '请输入新密码',
						trigger: 'blur'
					}],
					rePassword: [{
						required: true,
						message: '请输入确认密码',
						trigger: 'blur'
					}],
				},
				vis:'hidden',
				src:require('../../assets/ysmimg/addfile1.png'),
				savesrc:require('../../assets/ysmimg/save1.png'),
				printsrc:require('../../assets/ysmimg/print1.png'),
				backIndex:1,
				dname:''
			}
		},
		
		methods:{
			fanHui(){
				history.back();
			},
			printOrder(){
				var oid=sessionStorage.getItem("yloid");
				window.open("http://blht.techlion.com.cn:8089/client/order/downloadOrder?oid="+oid)
			},
			//关闭dialog之后,将表单数据情况
			resetpwdForm(formName) {
				this.$refs[formName].resetFields();
				this.pwdForm.oldPassword = '';
				this.pwdForm.password = '';
				this.pwdForm.rePassword = '';
			},
			//修改密码
			modifyPwd(formName) {
				this.dialogFormVisible = true;
				this.$refs[formName].validate((valid) => {
					if (valid) {
						//1.获取当前登录用户的密码
						let pwd = JSON.parse(sessionStorage.getItem("loginInfo")).password;
						if (pwd != this.pwdForm.oldPassword) {
							this.$alert("原密码输入有误", "提示");
						} else if (this.pwdForm.password != this.pwdForm.rePassword) {
							this.$alert("两次密码不一致", "提示");
						} else {
							
							this.pwdForm.id=JSON.parse(sessionStorage.getItem("loginInfo")).id;
							this.$axios.post("/client/doctor/updPwd", JSON.stringify(this.pwdForm), {
								headers: {
									"Content-type": "application/json"
								}
							}).then(res => {
								let _this = this;
								window.setTimeout(function() {
									_this.dialogFormVisible = false;
									_this.$router.push("/login");
								}, 500)
			
							}).catch(err => {
								this.$alert("请联系管理员", "提示");
							})
						}
			
			
					} else {
						// this.$alert('个人信息不完成',"提示");
						return false;
					}
				});
			},
			goOrder(){
				/* document.getElementsByClassName("fun")[0].setAttribute("class","fun sub-menue visible")
				document.getElementsByClassName("fun1")[0].setAttribute("class","fun1 sub-menue1")
				document.getElementsByClassName("fun2")[0].setAttribute("class","fun2 sub-menue2") */
				this.$router.push("/hxmain/hxlatestorder");
			},
			settings(){
				/* document.getElementsByClassName("fun")[0].setAttribute("class","fun sub-menue visible")
				document.getElementsByClassName("fun1")[0].setAttribute("class","fun1 sub-menue1")
				document.getElementsByClassName("fun2")[0].setAttribute("class","fun2 sub-menue2")
				document.getElementsByClassName("fun3")[0].setAttribute("class","fun3 sub-menue3") */
				this.$router.push("/hxmain/hxsettings");
			},
			//退出
			logout(){
				this.$confirm('确定要退出吗?', '提示', {
				          confirmButtonText: '确定',
				          cancelButtonText: '取消',
				          type: 'warning'
				        }).then(() => {
				        sessionStorage.removeItem("loginInfo");
				        sessionStorage.removeItem("oid");
				        this.$router.push("/");
				        }).catch(() => {
				          this.$alert("您取消了退出操作","提示");         
				        });
				
			},
			//去首页
			goMain(){
				this.$router.push("/selecttype");
			},
			//鼠标悬浮到添加订单的时候
			change(){
				this.src=require('../../assets/hximg/addfile2.png');
				document.getElementById("addO").style.color="#0D8EA4";
			},
			//鼠标悬浮到添加订单的时候
			leavechange(){
				this.src=require('../../assets/ysmimg/addfile1.png');
				document.getElementById("addO").style.color="#FFFFFF";
			},
			entersave(){
				this.savesrc=require('../../assets/hximg/save1.png');
				document.getElementById("bc").style.color="#0D8EA4";
			},
			leavesave(){
				this.savesrc=require('../../assets/ysmimg/save1.png');
				document.getElementById("bc").style.color="#FFFFFF";
			},
			enterprint(){
				this.printsrc=require('../../assets/hximg/print1.png');
				document.getElementById("dy").style.color="#0D8EA4";
			},
			leaveprint(){
				this.printsrc=require('../../assets/ysmimg/print1.png');
				document.getElementById("dy").style.color="#FFFFFF";
			},
			//返回
			back(){
				if(this.backIndex==2){
					/* document.getElementsByClassName("fun1")[0].setAttribute("class","fun1 sub-menue1")
					document.getElementsByClassName("fun")[0].setAttribute("class","fun sub-menue visible") */
				}
				// this.$store.commit("setYsmStepIndex",1);
				let pa=this.$store.state.backLocation;
				let backPath="";
				this.$router.push(pa);
				 if(pa=="/hxmain/hxlatestorder"){
					/* document.getElementsByClassName("fun1")[0].setAttribute("class","fun1 sub-menue1");
					document.getElementsByClassName("fun")[0].setAttribute("class","fun sub-menue");
					document.getElementsByClassName("fun2")[0].setAttribute("class","fun2 sub-menue2");
					document.getElementsByClassName("fun3")[0].setAttribute("class","fun3 sub-menue3"); */
				    backPath="/hxmain/hxlatestorder";
				}
				else if(pa=="/hxmain/hxorder/hxorderone"){
					this.$store.commit("setHxStepIndex",1);
					backPath="/hxmain/hxorder/hxorderone";
				}else if(pa=="/hxmain/hxorder/hxordertwo"){
					this.$store.commit("setHxStepIndex",2);
					backPath="/hxmain/hxorder/hxordertwo";
					
				}else if(pa=="/hxmain/hxorder/hxorderthree"){
					this.$store.commit("setHxStepIndex",3);
					backPath="/hxmain/hxorder/hxorderthree";
					
				}else if(pa=="/hxmain/hxorder/hxorderfour"){
					this.$store.commit("setHxStepIndex",4);
					backPath="/hxmain/hxorder/hxorderfour";
				}else if(pa=="/hxmain/hxorder/hxorderfive"){
					this.$store.commit("setHxStepIndex",5);
					backPath="/hxmain/hxorder/hxorderfive";
				}else if(pa=="/hxmain/hxorder/hxordersix"){
					this.$store.commit("setHxStepIndex",6);
					backPath="/hxmain/hxorder/hxorderfive";
				}else if(pa=="/hxmain/hxorder/hxorderseven"){
					this.$store.commit("setHxStepIndex",7);
						backPath="/hxmain/hxorder/hxordersix";
				}else if(pa=="/hxmain/hxorder/hxordereight"){
					this.$store.commit("setHxStepIndex",8);
						backPath="/hxmain/hxorder/hxorderseven";
				}
				else if(pa=="/hxmain/hxorderjindu"){
					backPath="/hxmain/hxlatestorder";
				}else if(pa=="/hxmain/hxorder/hxorderone"){
					backPath="/hxmain/hxlatesetorder"
				}else if(pa=="/hxmain/hxorder/hxorderten"){
					backPath="/hxmain/hxorder/hxorderten";
				}else if(pa=="/hxmain/hxorderyulan"){
					backPath="/hxmain/hxlatestorder";
				}else if(pa=="/hxmain/hxorder/hxordereleven"){
					backPath="/hxmain/hxlatestorder";
				}
				
				this.$router.push(backPath); 
			},
			//单击添加订单
			goAddOrder(){
				sessionStorage.removeItem("oid");
				//this.backIndex=2;
				/* document.getElementsByClassName("fun")[0].setAttribute("class","fun sub-menue")
				document.getElementsByClassName("fun1")[0].setAttribute("class","fun1 sub-menue1 visible")
				document.getElementsByClassName("fun2")[0].setAttribute("class","fun2 sub-menue2")
				document.getElementsByClassName("fun3")[0].setAttribute("class","fun3 sub-menue3 ") */
				this.$store.commit("setBackLocation","/hxmmain/hxlatestorder");
				
				this.$router.push("/hxmain/hxorder/hxorderone");
			},
			//打印
			printOrder(){
				var oid=sessionStorage.getItem("yloid");
				window.open("http://blht.techlion.com.cn:8089/client/order/downloadOrder?oid="+oid)
			},
			//保存订单
			SaveOrder(){
				// var currentPath=this.$route.path;
				// console.log(currentPath)
				// if(currentPath=="/hxmain/hxorder/hxorderone"){
				// 	console.log(this);
				// 	//One.methods.a();
				// }
				let oid=sessionStorage.getItem("oid");
				
				if(oid!="undefined"&&oid!=''&&oid!=null&&oid!=undefined){
					
					//根据oid查询订单信息
					this.$axios.get("/client/order/saveOrderFake",{
						params:{
							oid:oid
						}
					}).then(res=>{
						if(res.data.code==200){
							this.$alert("保存订单成功","提示");
						}else if(res.data.code==500){
							this.$alert("保存订单失败","提示");
						}
						
						
					}).catch(err=>{
						this.$alert("系统出现异常,请联系管理员!");
					});
				}else{
					this.$alert("当前订单信息有误，请回到订单列表重新进入","提示");
				}
				
			},
			goSettings(){
				this.$router.push("/hxmain/hxsettings");
			}
		},
		mounted:function(){
			
			if(this.$route.path=="/hxmain/hxlatestorder"){
				/* document.getElementsByClassName("fun1")[0].setAttribute("class","fun1 sub-menue1")
				document.getElementsByClassName("fun")[0].setAttribute("class","fun sub-menue");
				document.getElementsByClassName("fun2")[0].setAttribute("class","fun2 sub-menue2");
				document.getElementsByClassName("fun3")[0].setAttribute("class","fun3 sub-menue3"); */
			}
			if(this.$route.path=="/hxmain/hxsettings"){
				/* document.getElementsByClassName("fun1")[0].setAttribute("class","fun1 sub-menue1")
				document.getElementsByClassName("fun2")[0].setAttribute("class","fun2 sub-menue2");
				document.getElementsByClassName("fun")[0].setAttribute("class","fun sub-menue visible")
				document.getElementsByClassName("fun3")[0].setAttribute("class","fun3 sub-menue3"); */
			}
			let doctorInfo=sessionStorage.getItem("loginInfo");
			let doctorJson=JSON.parse(doctorInfo);
			this.dname=doctorJson.dname;
			//将当前路径存储到backLocation
			this.$store.commit("setHxBackLocation","/hxmain/hxlatestorder");
		},
		created:function(){
			
		}
		
	}
</script>

<style scoped="scoped">
	.yh{
		width: 100%;
		position: fixed;
		top: 0;
		z-index: 10002;
		background-color: #FDFDFD;
		
	}
	.ysm-head{
		width:100%;
		height:77px;
		line-height: 77px;
		background-color: white;
		position: fixed;
		align-items: center;
	}
	.nav1{
		/* top:6.5125rem; */
		top: 75px;
		width: 100%;
		height: 46px;		
		line-height: 46px;
		background-color: rgba(68,68,68,.8);	
		padding-left: 0rem;
		display: flex;
		justify-content: space-between;
		position: fixed;
		z-index: 10002;
	}
	.nav1 a{
		color: white;
		font-weight: bolder;
		margin-right: 2.8rem;
		cursor: pointer;
	}
	.nav1 .saveAndback .saveb,.nav1 .saveAndback .print{
		margin-right: 20px;
	}
	/* 退出按钮 */
	.logout{
		/* border: 1px solid red; */
		min-width: 6.25rem;
		height: 2.125rem !important;
		background-color: #0D8EA4 !important;
		/* border-color: #0D8EA4 !important; */
		border: none;
		color: #fff !important;
		padding: 6px 6px !important;
		text-align: center;
		display: inline-block;
		cursor: pointer;
	}
	/* 子菜单 */
	.fun{
		background-color: rgba(68,68,68,.8);
		position: fixed;
		width: 100%;
		z-index: 10002;
		top: 9.2rem;
	}
	.fun1{
		background-color:rgba(68,68,68,.8);
		position: fixed;
		width: 100%;
		z-index: 10002;
		top: 9.2rem;
	}
	.fun2{
			background-color:rgba(68,68,68,.8);
			position: fixed;
			width: 100%;
			z-index: 10002;
			top: 9.2rem;
	}
	.fun3{
			background-color:rgba(68,68,68,.8);
			position: fixed;
			width: 100%;
			z-index: 10002;
			top: 9.2rem;
	}
	.sub-menue{
		/* height: 5rem; */
		height: 65px;
		visibility: hidden;
		opacity: 0;
		transition: visibility .3s linear,opacity .3s linear;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding-left: 1.5rem;
	}
	.sub-menue1{
		/* height: 6rem; */
		height: 65px;
		visibility: hidden;
		opacity: 0;
		transition: visibility .3s linear,opacity .3s linear;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding-left: 1.5rem;
	}
	.sub-menue2{
		/* height: 6rem; */
		height: 70px;
		visibility: hidden;
		opacity: 0;
		transition: visibility .3s linear,opacity .3s linear;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding-left: 1.5rem;
	}
	.sub-menue3{
		/* height: 6rem; */
		height: 70px;
		visibility: hidden;
		opacity: 0;
		transition: visibility .3s linear,opacity .3s linear;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding-left: 1.5rem;
	}
	.visible{
		visibility:visible;
		opacity: 1;
	}
	.info{
		position: relative;
		z-index: 10003 !important;
		top: 70px !important;
	}
	.die{
		display: none;
	}
	.show{
		display: inline-block;
	}
</style>
